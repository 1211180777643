import Link from 'next/link'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { LINKS } from 'constants/links'

import { HeaderProfile } from 'components/header/profile/HeaderProfile'
import { Notifications } from 'components/notifications/Notifications'

import { useSession } from 'driverama-core/utils/auth'

import IconClipboard from 'images/icons/IconClipboard.svg'
import IconManager from 'images/icons/IconManager.svg'
import IconMarketingSearch from 'images/icons/IconMarketingSearch.svg'
import IconRepairs from 'images/icons/IconRepairs.svg'

import {
  SContainer,
  SDriveramaLogo,
  SHeaderActions,
  SHeaderLinksWrapper,
  SInputSearch,
  SLogo
} from './Header.styled'

import { useRouter } from 'next/router'
import { HeaderLink } from './HeaderLink'

interface Props {
  defaultSearch?: string | null
  searchCar?: (value: string) => void
  variant?: 'night' | 'white' | 'transparent'
  hasShadow?: boolean
  className?: string
  carId?: string
  searchInputPlaceholder?: string
}

export function Header({
  defaultSearch,
  searchCar,
  variant,
  hasShadow,
  className,
  carId,
  searchInputPlaceholder
}: Props) {
  const { t } = useTranslation(['core'])
  const router = useRouter()

  const [search, setSearch] = useState(defaultSearch)

  const { session, loading } = useSession()

  const isLoginPage = router.pathname === LINKS.login
  const isUser = session?.access_token !== undefined && !loading

  const handleSearch = (value: string) => {
    setSearch(value)

    if (searchCar) {
      searchCar(value)
    }
  }

  return (
    <SContainer
      hasShadow={hasShadow}
      background={variant}
      className={className}
    >
      <SDriveramaLogo>
        <Link href={LINKS.home} passHref>
          <a>
            <SLogo />
          </a>
        </Link>
      </SDriveramaLogo>

      {!isLoginPage && (
        <>
          <SHeaderLinksWrapper
            variant="row"
            justify="center"
            align="center"
            css={{ flex: 1, display: isLoginPage ? 'none' : 'flex' }}
            gap={6}
          >
            {isUser && (
              <>
                {driverama.flags.STOK_915_SERVICE_MODULE_ENABLED && (
                  <HeaderLink
                    href={LINKS.repairs}
                    label={t('core:header_repair_link')}
                    Icon={<IconRepairs />}
                  />
                )}

                <HeaderLink
                  href={LINKS.home}
                  label={t('core:header_home_link')}
                  Icon={<IconManager />}
                />

                <HeaderLink
                  href={LINKS.marketing}
                  label={t('core:header_marketing_link')}
                  Icon={<IconMarketingSearch />}
                />

                <HeaderLink
                  href={LINKS.orders}
                  label={t('core:header_orders_link')}
                  Icon={<IconClipboard />}
                />
              </>
            )}
          </SHeaderLinksWrapper>

          <SHeaderActions>
            {searchCar && (
              <SInputSearch
                name="searchInput"
                noOutline={true}
                value={search ?? ''}
                onChange={setSearch}
                label={t('core:header_search_label')}
                placeholder={
                  searchInputPlaceholder ?? t('core:header_search_placeholder')
                }
                onSubmit={() => {
                  handleSearch(search ?? '')
                }}
                onClear={() => handleSearch('')}
              />
            )}

            {carId && <Notifications />}
            {isUser && <HeaderProfile />}
          </SHeaderActions>
        </>
      )}
    </SContainer>
  )
}
