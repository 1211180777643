import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'

import { TextBody } from 'driverama-core/components/text/Text'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'
import {
  color,
  radius,
  shadow,
  weight,
  zIndex
} from 'driverama-core/styles/variables'

export const SNotificationsButton = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${size(10.5)};
  width: ${size(10.5)};
  margin-right: ${size(2)};

  color: ${color('night-l-200')};
  border-radius: ${radius('full')};
  border: 2px solid ${color('night-l-700')};

  cursor: pointer;

  &:hover {
    background-color: ${color('night-l-700')};
  }
`

export const SNotificationBadge = styled.div`
  position: absolute;
  top: ${size(-1)};
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  height: ${size(4)};
  width: ${size(4)};

  border-radius: ${radius('full')};
  background-color: ${color('warning')};
  color: ${color('white')};

  font-size: 11px;
  font-weight: ${weight('bold')};
`

export const SNotifications = styled.div`
  position: relative;
`

export const SNotificationsContainer = styled(motion.div)`
  position: absolute;
  top: ${size(14)};
  right: 0;

  width: ${size(90)};
  max-height: ${size(90)};
  overflow-y: scroll;
  z-index: ${zIndex('order-2')};

  background-color: ${color('white')};
  border-radius: ${radius('corner')};

  box-shadow: ${shadow(2)};

  @media ${media.lte('SM')} {
    right: -50px;
  }
`

export const SNotificationsHeader = styled.div`
  position: sticky;
  top: 0;
  background-color: ${color('white')};

  z-index: ${zIndex('order-1')};
  padding: ${size(6)};

  display: flex;
  justify-content: space-between;

  border-bottom: 2px solid ${color('night-l-700')};
`

export const SNotificationItem = styled.div<{ isLast?: boolean }>`
  display: flex;

  padding: ${size(2)} 0;

  margin: 0 ${size(5)};

  ${({ isLast }) =>
    isLast
      ? ''
      : css`
          border-bottom: 2px solid ${color('night-l-700')};
        `}
`

export const SNotificationItemTitle = styled(TextBody)`
  color: ${color('black')};
  font-weight: ${weight('bold')};
`

export const SNotificationItemBody = styled(TextBody)`
  font-size: ${size(3)};
  word-break: break-all;
`

export const SNotificationItemTime = styled(TextBody)`
  font-size: ${size(3)};
`

export const SEmptyNotifications = styled(TextBody)`
  text-align: center;
  width: 100%;
  padding: ${size(6)};
`
