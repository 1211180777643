import { Flex } from 'driverama-core/components/Flex'
import { Spacer } from 'driverama-core/components/spacer/Spacer'

import NewNotificationBulletIcon from 'images/icons/NewNotificationBulletIcon.svg'
import NotificationBulletIcon from 'images/icons/NotificationBulletIcon.svg'

import { parseUTCDate } from 'driverama-core/utils/date'
import { useTranslation } from 'react-i18next'
import {
  SNotificationItem,
  SNotificationItemBody,
  SNotificationItemTime,
  SNotificationItemTitle
} from './Notifications.styled'
import { Notification } from './Notifications.utils'

type Props = Notification & {
  isNewest?: boolean
  isLast?: boolean
}

export function NotificationItem({
  body,
  title,
  createdAt,
  isNewest = false,
  isLast = false
}: Props) {
  const { t } = useTranslation(['core'])

  return (
    <SNotificationItem isLast={isLast}>
      <div style={{ display: 'flex', marginTop: 8 }}>
        {isNewest ? <NewNotificationBulletIcon /> : <NotificationBulletIcon />}
      </div>
      <Spacer size={4} />

      <Flex variant="column">
        <Flex variant="column">
          <SNotificationItemTitle>{title}</SNotificationItemTitle>
          <SNotificationItemBody>{body}</SNotificationItemBody>
        </Flex>

        {createdAt && (
          <SNotificationItemTime size="small">
            {t('core:date_time_format', { date: parseUTCDate(createdAt) })}
          </SNotificationItemTime>
        )}
      </Flex>
    </SNotificationItem>
  )
}
