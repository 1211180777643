import { ButtonTransparent } from 'driverama-core/components/button/Button'
import { TextHeader } from 'driverama-core/components/text/Text'
import { color } from 'driverama-core/styles/variables'
import { AnimatePresence } from 'framer-motion'
import BellIcon from 'images/icons/IconBell.svg'
import CloseIcon from 'images/icons/IconClose.svg'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useKey } from 'react-use'
import { NotificationItem } from './NotificationItem'
import {
  SEmptyNotifications,
  SNotificationBadge,
  SNotifications,
  SNotificationsButton,
  SNotificationsContainer,
  SNotificationsHeader
} from './Notifications.styled'
import { NotificationContext } from './NotificationsContext'

export function Notifications() {
  const { t } = useTranslation(['core'])

  const {
    notifications,
    openDropdown,
    closeNotifications,
    toggleNotifications
  } = useContext(NotificationContext)

  useKey('Escape', () => {
    closeNotifications()
  })

  const hasNotifications = notifications.length > 0

  return (
    <SNotifications>
      <SNotificationsButton onClick={toggleNotifications}>
        <BellIcon />
        {hasNotifications && (
          <SNotificationBadge>{notifications.length}</SNotificationBadge>
        )}
      </SNotificationsButton>

      <AnimatePresence>
        {openDropdown && (
          <SNotificationsContainer
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            {hasNotifications ? (
              <SNotificationsHeader>
                <TextHeader variant="h5">
                  {t('core:header_notifications')}
                </TextHeader>

                <ButtonTransparent onClick={closeNotifications}>
                  <CloseIcon color={color('almond-d-200')} />
                </ButtonTransparent>
              </SNotificationsHeader>
            ) : (
              <SEmptyNotifications>
                {t('core:header_notifications_empty')}
              </SEmptyNotifications>
            )}

            {notifications.map((notification, index) => (
              <NotificationItem
                key={notification.id}
                {...notification}
                isNewest={index === 0}
                isLast={index === notifications.length - 1}
              />
            ))}
          </SNotificationsContainer>
        )}
      </AnimatePresence>
    </SNotifications>
  )
}
